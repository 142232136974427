import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Constants } from "../src/config";
import { getClass } from "../src/helpers/formatHelpers";
import { TouchableOpacity } from "react-native-web";

const SideMenu = ({
  sideMenuShow,
  setSideMenuShow,
  sideMenuHeaderShow,
  setSideMenuHeaderShow,
}) => {
  return (
    <div>
      <div
        className={`fixed top-0 left-0 ${getClass(
          "bg-black7",
          true
        )} h-screen overflow-y-auto w-305px ${
          sideMenuShow
            ? "translate-x-0 opacity-100"
            : "-translate-x-full opacity-0"
        } transition-all duration-300 h-full z-[999]`}>
        <div className="pt-10 pl-8">
          <div
            className="cursor-pointer inline-block"
            onClick={() => setSideMenuShow(false)}>
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.646446 1.35355C0.451185 1.15829 0.451185 0.841709 0.646446 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L8.5 7.79289L15.6464 0.646447C15.8417 0.451184 16.1583 0.451184 16.3536 0.646447C16.5488 0.841709 16.5488 1.15829 16.3536 1.35355L9.20711 8.5L16.3536 15.6464C16.5488 15.8417 16.5488 16.1583 16.3536 16.3536C16.1583 16.5488 15.8417 16.5488 15.6464 16.3536L8.5 9.20711L1.35355 16.3536C1.15829 16.5488 0.841709 16.5488 0.646447 16.3536C0.451185 16.1583 0.451185 15.8417 0.646447 15.6464L7.79289 8.5L0.646446 1.35355Z"
                fill={getClass("svg-white")}></path>
            </svg>
          </div>
        </div>
        <div
          // id={
          //   process.env.NEXT_PUBLIC_APP_NAME != "PrefabWorld" &&
          //   process.env.NEXT_PUBLIC_APP_NAME != "WorldCasino" &&
          //   process.env.NEXT_PUBLIC_APP_NAME != "LuxResort Club" &&
          //   process.env.NEXT_PUBLIC_APP_NAME != "AparthotelClub"
          //     ? "sub-menu-prelaunch"
          //     : ""
          // }
          className={`border-b ${getClass("border-white2")} pt-7 pb-4 pl-8`}>
          <div
            className={`text-base ${getClass(
              "text-white",
              true
            )} font-bold cursor-pointer`}
            // onClick={() => setSideMenuHeaderShow(!sideMenuHeaderShow)}
          >
            {process.env.NEXT_PUBLIC_APP_NAME}
          </div>
          {/* {sideMenuHeaderShow &&
            process.env.NEXT_PUBLIC_APP_NAME != "Zoopedia" &&
            process.env.NEXT_PUBLIC_APP_NAME != "PrefabWorld" &&
            process.env.NEXT_PUBLIC_APP_NAME != "Parkful" &&
            process.env.NEXT_PUBLIC_APP_NAME != "WorldCasino" &&
            process.env.NEXT_PUBLIC_APP_NAME != "Castlepedia" &&
            process.env.NEXT_PUBLIC_APP_NAME != "LuxResort Club" &&
            process.env.NEXT_PUBLIC_APP_NAME != "AparthotelClub" && (
              <div className="p-0">
                {Constants.websiteList
                  ?.filter(
                    (item) => item.label !== process.env.NEXT_PUBLIC_APP_NAME
                  )
                  ?.map((item, index) => (
                    <a
                      className={`block text-base ${getClass(
                        "text-gray2-dark",
                        true
                      )} font-regular py-3`}
                      href={item.link}>
                      {item.label?.replace(" ", "")}
                    </a>
                  ))}
              </div>
            )} */}
          {/* {sideMenuHeaderShow &&
            (process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Parkful" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") && (
              <div className="p-0">
                {Constants.websiteList2
                  ?.filter(
                    (item) => item.label !== process.env.NEXT_PUBLIC_APP_NAME
                  )
                  ?.map((item, index) => (
                    <a
                      className={`block text-base ${getClass(
                        "text-gray2-dark",
                        true
                      )} font-regular py-3`}
                      href={item.link}>
                      {item.label?.replace(" ", "")}
                    </a>
                  ))}
              </div>
            )} */}
        </div>
        <div className={`pl-8 pt-1 py-3 border-b ${getClass("border-white2")}`}>
          <Link prefetch={false} href="/">
            <a
              className={`block text-base ${getClass(
                "text-white",
                true
              )} font-regular uppercase py-3`}>
              Home
            </a>
          </Link>
          {/* {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
            (i) => i === "Festivals" || i === "Artists"
          ) && (
            <Link prefetch={false} href="/charts/festivals">
              <a
                className={`block text-base ${getClass(
                  "text-white",
                  true
                )} font-regular uppercase py-3`}>
                Charts
              </a>
            </Link>
          )}
          {process.env.NEXT_PUBLIC_CATEGORY_ACTIVE.split(",").find(
            (i) => i === "Festivals" || i === "Artists"
          ) && (
            <Link prefetch={false} href="/playlist">
              <a
                className={`block text-base ${getClass(
                  "text-white",
                  true
                )} font-regular uppercase py-3`}>
                Playlist
              </a>
            </Link>
          )} */}
          <Link prefetch={false} href="/guides">
            <a
              className={`block text-base ${getClass(
                "text-white",
                true
              )} font-regular uppercase py-3`}>
              Guides
            </a>
          </Link>
          <Link
            prefetch={false}
            href={`/${process.env.NEXT_PUBLIC_APP_NAME?.replace(
              " ",
              ""
            )?.toLowerCase()}-pro/`}>
            <a
              className={`block text-base ${getClass(
                "text-white",
                true
              )} font-regular uppercase py-3`}>
              Apply
            </a>
          </Link>
          <Link prefetch={false} href="/about">
            <a
              className={`block text-base ${getClass(
                "text-white",
                true
              )} font-regular uppercase py-3`}>
              About
            </a>
          </Link>
          {/* <Link prefetch={false} href="/platform">
          <a className={`block text-base ${getClass('text-white',true)} font-regular uppercase py-3`}>
            Platform
          </a>
        </Link>
        <Link prefetch={false} href="/careers">
          <a className={`block text-base ${getClass('text-white',true)} font-regular uppercase py-3`}>
            Careers
          </a>
        </Link> */}
          <Link prefetch={false} href="/contact">
            <a
              className={`block text-base ${getClass(
                "text-white",
                true
              )} font-regular uppercase py-3`}>
              Contact
            </a>
          </Link>
        </div>
        {/* <div className="flex flex-row items-center border-b border-white2 pl-8 py-6">
        <div className="px-2">
          <a
            href="https://web.facebook.com/Soundclub20/"
            aria-label={`Facebook ${process.env.NEXT_PUBLIC_APP_NAME}`}>
            <Image
              src="/icons/ic_facebook_circle.svg"
              width="20"
              height="20"
              alt="facebook"
            />
          </a>
        </div>
        <div className="px-2">
          <a
            href="https://www.instagram.com/soundclub20"
            aria-label={`Instagram ${process.env.NEXT_PUBLIC_APP_NAME}`}>
            <Image
              src="/icons/ic_instagram_circle.svg"
              width="20"
              height="20"
              alt="instagram"
            />
          </a>
        </div>
        <div className="px-2">
          <a
            href="https://twitter.com/soundclub20"
            aria-label={`Twitter ${process.env.NEXT_PUBLIC_APP_NAME}`}>
            <Image
              src="/icons/ic_twitter_circle.svg"
              width="20"
              height="20"
              alt="twitter"
            />
          </a>
        </div>
      </div> */}
        <div className="pl-8 pt-1 py-3">
          {/* <Link prefetch={false} href="/help-center">
          <a className={`block text-base ${getClass('text-gray2-dark', true)} font-regular py-3`}>
            Help Center
          </a>
        </Link> */}
          {/* <Link
          prefetch={false}
          href="https://drive.google.com/drive/folders/1wsCVka-wEssR_yBk6jWXQG4xuPK8zbgI">
          <a className={`block text-base ${getClass('text-gray2-dark', true)} font-regular py-3`}>
            Media Kit
          </a>
        </Link> */}
          {process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" && (
            <a
              href="/gambling-help"
              className={`block text-base ${getClass(
                "text-gray2-dark",
                true
              )} font-regular py-3`}>
              Gambling Help
            </a>
          )}
          <a
            href="/cookie-policy"
            className={`block text-base ${getClass(
              "text-gray2-dark",
              true
            )} font-regular py-3`}>
            Cookies
          </a>
          <Link prefetch={false} href="/legal">
            <a
              className={`block text-base ${getClass(
                "text-gray2-dark",
                true
              )} font-regular py-3`}>
              Legal
            </a>
          </Link>
          <Link prefetch={false} href="/sitemap.xml">
            <a
              className={`block text-base ${getClass(
                "text-gray2-dark",
                true
              )} font-regular py-3`}>
              Sitemap
            </a>
          </Link>
        </div>
      </div>
      {sideMenuShow && (
        <TouchableOpacity
          style={{
            backgroundColor: "rgba(89, 89, 89, 0.45)",
            width: "100%",
            height: "100%",
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: 500,
          }}
          onPress={() => setSideMenuShow(false)}
        />
      )}
    </div>
  );
};

export default SideMenu;
