import React from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import Image from "next/image";

import { CImage } from "../src/components/atoms";
import { Constants } from "../src/config";
import { getClass } from "../src/helpers/formatHelpers";

const Header = ({ setSideMenuShow }) => {
  const { t } = useTranslation();
  return (
    <div className={`${getClass("header", true)}`}>
      <div
        className={`md:block hidden border-b ${getClass(
          "border-grayBackground"
        )}`}>
        <div className="wrapper mx-auto">
          <div className="flex flex-row py-2 items-center justify-between">
            <div className="flex flex-row items-center">
              <div className="pr-[36px] relative group">
                <Link prefetch={false} href="/">
                  <a
                    className="text-sm text-white font-bold"
                    // id={
                    //   process.env.NEXT_PUBLIC_APP_NAME != "PrefabWorld" &&
                    //   process.env.NEXT_PUBLIC_APP_NAME != "WorldCasino" &&
                    //   process.env.NEXT_PUBLIC_APP_NAME != "LuxResort Club" &&
                    //   process.env.NEXT_PUBLIC_APP_NAME != "AparthotelClub"
                    //     ? "sub-menu-prelaunch-header"
                    //     : ""
                    // }
                  >
                    {process.env.NEXT_PUBLIC_APP_NAME}
                  </a>
                </Link>
                {/* {process.env.NEXT_PUBLIC_APP_NAME != "Zoopedia" &&
                  process.env.NEXT_PUBLIC_APP_NAME != "PrefabWorld" &&
                  process.env.NEXT_PUBLIC_APP_NAME != "Parkful" &&
                  process.env.NEXT_PUBLIC_APP_NAME != "WorldCasino" &&
                  process.env.NEXT_PUBLIC_APP_NAME != "Castlepedia" &&
                  process.env.NEXT_PUBLIC_APP_NAME != "LuxResort Club" &&
                  process.env.NEXT_PUBLIC_APP_NAME != "AparthotelClub" && (
                    <div
                      className={`opacity-0 group-hover:opacity-100 invisible group-hover:visible absolute z-20 px-3 py-2 ${getClass(
                        "background",
                        true
                      )} w-[120px] -ml-3 rounded-lg`}>
                      {Constants.websiteList
                        ?.filter(
                          (item) =>
                            item.label !== process.env.NEXT_PUBLIC_APP_NAME
                        )
                        ?.map((item, index) => (
                          <a
                            key={index}
                            className="block text-sm text-grayText2 font-regular py-2"
                            href={item.link}>
                            {item.label?.replace(" ", "")}
                          </a>
                        ))}
                    </div>
                  )} */}
                {/* {(process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Parkful" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia") && (
                  <div
                    className={`opacity-0 group-hover:opacity-100 invisible group-hover:visible absolute z-20 px-3 py-2 ${getClass(
                      "background",
                      true
                    )} w-[120px] -ml-3 rounded-lg`}>
                    {Constants.websiteList2
                      ?.filter(
                        (item) =>
                          item.label !== process.env.NEXT_PUBLIC_APP_NAME
                      )
                      ?.map((item, index) => (
                        <a
                          key={index}
                          className="block text-sm text-grayText2 font-regular py-2"
                          href={item.link}>
                          {item.label?.replace(" ", "")}
                        </a>
                      ))}
                  </div>
                )} */}
              </div>
              {/* {(process.env.NEXT_PUBLIC_APP_NAME === "Vybeful" ||
                process.env.NEXT_PUBLIC_APP_NAME === "Festyful") && (
                <div className="pl-3 pr-4">
                  <a
                    className="text-sm font-regular text-grayText2"
                    href="https://www.soundvibemag.com/">
                    {t("key_web_mag")}
                  </a>
                </div>
              )} */}
              {/* <div className="px-4">
                <a
                  className="text-sm font-regular text-grayText2"
                  href="https://pro.soundclub.com/">
                  {t("key_web_pro")}
                </a>
              </div> */}
              {/* <div className="px-4">
                <a
                  className="text-sm font-regular text-grayText2"
                  href="https://store.soundclub.com/">
                  {t("key_web_store")}
                </a>
              </div> */}
            </div>
            <div className="flex flex-row items-center">
              <div
                className={`${getClass(
                  "btn-border"
                )} rounded-full px-3 pb-1 mr-0 h-[30px] flex justify-center items-center w-104px text-center`}>
                {/* <div className="border border-tabBottomColor rounded-full px-3 pb-1 mr-6 h-[30px] flex justify-center items-center w-104px text-center"> */}
                <Link
                  prefetch={false}
                  href={`/${process.env.NEXT_PUBLIC_APP_NAME?.replace(
                    " ",
                    ""
                  )?.toLowerCase()}-pro/`}>
                  <a
                    className={`text-sm h-[16px] font-semibold ${getClass(
                      "text-white",
                      true
                    )}`}>
                    {t("key_web_apply")}
                  </a>
                </Link>
              </div>
              {/* <div className="flex flex-row items-center">
                <div className="px-1" style={{ height: 18 }}>
                  <a href="https://web.facebook.com/Soundclub20/">
                    <Image
                      src="/icons/ic_facebook_circle.svg"
                      width={18}
                      height={18}
                      alt="facebook"
                    />
                  </a>
                </div>
                <div className="px-1" style={{ height: 18 }}>
                  <a href="https://www.instagram.com/soundclub20">
                    <Image
                      src="/icons/ic_instagram_circle.svg"
                      width={19}
                      height={18}
                      alt="instagram"
                    />
                  </a>
                </div>
                <div className="px-1" style={{ height: 18 }}>
                  <a href="https://twitter.com/soundclub20">
                    <Image
                      src="/icons/ic_twitter_circle.svg"
                      width={18}
                      height={18}
                      alt="twitter"
                    />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`wrapper-no-padding mx-auto ${
          process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
          process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
          process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
            ? "py-1"
            : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
            ? "py-1"
            : "py-4"
        } sm:px-9 px-4`}>
        <div className="flex flex-row items-center justify-between">
          {/* Desktop */}
          <div
            className="sm:block hidden cursor-pointer w-[150px]"
            onClick={() => setSideMenuShow(true)}
            style={{ height: 20 }}>
            <CImage
              source="/icons/ic_menu.svg"
              width={29}
              height={20}
              layout="fixed"
              alt="menu"
            />
          </div>
          {/* Mobile */}
          <div
            className="sm:hidden block cursor-pointer w-[30px]"
            onClick={() => setSideMenuShow(true)}
            style={{ height: 22 }}>
            <CImage
              source="/icons/ic_menu_mobile.svg"
              width={30}
              height={22}
              layout="fixed"
              alt="menu"
            />
          </div>

          {/* Desktop */}
          <Link prefetch={false} href="/">
            <a
              className="sm:block hidden"
              style={{
                height:
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 70
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 70
                    : 52.8,
              }}>
              <CImage
                source={process.env.NEXT_PUBLIC_APP_LOGO}
                width={
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 330
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 220
                    : 182
                }
                height={
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 70
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 70
                    : 52.8
                }
                layout="fixed"
                alt={`${process.env.NEXT_PUBLIC_APP_NAME} logo`}
              />
            </a>
          </Link>
          {/* Mobile */}
          <Link prefetch={false} href="/">
            <a
              className="sm:hidden block"
              style={{
                height:
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 50
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 45
                    : 34,
              }}>
              <CImage
                source={process.env.NEXT_PUBLIC_APP_LOGO}
                width={
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 240
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 200
                    : 120
                }
                height={
                  process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "WorldCasino" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "Castlepedia"
                    ? 50
                    : process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                    ? 45
                    : 34
                }
                layout="fixed"
                alt={`${process.env.NEXT_PUBLIC_APP_NAME} logo`}
              />
            </a>
          </Link>
          <div className="sm:hidden block w-[30px]">&nbsp;</div>
          <div className="sm:block hidden w-[150px]">
            <Link href="/guides">
              <a
                className={`${getClass(
                  "btn-gradient"
                )} flex justify-center items-center w-150px h-40px rounded-full uppercase cursor-pointer`}>
                <div className="text-white text-base font-bold">Guides</div>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
